import React, { Component } from 'react'

const Filter = ({
  value,
  title,
  activeFilterValue,
  setFilterValue,
  manualAddAfter,
}) => {
  const selectedClass = value === activeFilterValue ? 'selected' : ''
  return (
    <li className={`product-filter ${selectedClass}`}>
      <a className="ui" onClick={setFilterValue} data-slug={value}>
        {title}
      </a>
    </li>
  )
}

class Dropdown extends Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
    this.toggle = this.toggle.bind(this)
    this.toggleOpen = this.toggleOpen.bind(this)
    this.toggleClose = this.toggleClose.bind(this)
  }

  toggle() {
    this.setState((prevState) => {
      return { isOpen: !prevState.isOpen }
    })
  }
  toggleOpen() {
    this.setState({ isOpen: true })
  }
  toggleClose() {
    this.setState({ isOpen: false })
  }

  render() {
    const filters = this.props.filters
    const setFilterValue = this.props.setFilterValue
    const activeFilterValue = this.props.activeFilterValue
    let activeFilter = filters.filter(
      (filter) => filter.node.slug === activeFilterValue,
    )
    const showTitle = activeFilter[0] ? activeFilter[0].node?.title : ''
    const manualAddAfter = this.props.manualAddAfter

    const isOpenClass = this.state.isOpen ? ' open' : ''
    return (
      <div className={`t-select ${isOpenClass}`}>
        <a className="ui header" onClick={this.toggle}>
          <div className="label selected">{showTitle}</div>
          <div className="arrow-sel" />
        </a>
        <div className="scroll">
          <ul onClick={this.toggleClose}>
            {filters.map((item, i) => {
              return (
                <Filter
                  key={i}
                  value={item.node.slug}
                  title={item.node.title}
                  activeFilterValue={activeFilterValue}
                  setFilterValue={setFilterValue}
                />
              )
            })}
            {manualAddAfter}
          </ul>
        </div>
      </div>
    )
  }
}
export default Dropdown
