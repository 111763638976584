import React, { Component } from 'react'
import Dropdown from 'components/dropdown'
import Layout from 'components/layout'
import ImageWrap from 'components/ImageWrap'
import LinkWrap from 'components/LinkWrap'
import Title from 'components/title'
import { dateFormatted, trFunction } from 'utils/functions'
import { graphql } from 'gatsby'

const News = ({ news, locale, tr, activeFilterValue }) => {
  return (
    <li
      className={
        'filterItem ' +
        (activeFilterValue === news.category.slug || activeFilterValue === 'all'
          ? 'show'
          : '')
      }
    >
      <ul className="news-single">
        <li className="img">
          <LinkWrap to={`/news/${news.slug}`}>
            <ImageWrap image={news.image} aspectRatio={5 / 3} />
          </LinkWrap>
        </li>
        <li className="txt">
          <div className="wrap-text-small">
            <div className="small-caps date">
              {dateFormatted(news.datePosted, locale)}
            </div>
            <h2 className="post-title">
              <LinkWrap to={`/news/${news.slug}`} className="dark">
                {news.title}
              </LinkWrap>
            </h2>
            {news.excerpt && (
              <div
                className="post-content"
                dangerouslySetInnerHTML={{
                  __html: news.excerpt.childMarkdownRemark.html,
                }}
              />
            )}
            <LinkWrap className="read-more" to={`/news/${news.slug}`}>
              {`${tr('READ_MORE')} »`}
            </LinkWrap>
          </div>
        </li>
      </ul>
    </li>
  )
}

const NewsCat = ({ slug, title, setFilterValue, activeFilterValue }) => {
  return (
    <li
      data-slug={slug}
      onClick={setFilterValue}
      className={activeFilterValue === slug ? 'selected' : ''}
    >
      <a className="ui">{title}</a>
    </li>
  )
}

class NewsPage extends Component {
  constructor() {
    super()
    this.state = {
      activeFilterValue: 'all',
    }
    this.setFilterValue = this.setFilterValue.bind(this)
  }

  setFilterValue(e) {
    const dataSlug = e.currentTarget.getAttribute('data-slug')
    this.setState({ activeFilterValue: dataSlug })
  }

  render() {
    const locale = this.props.data.site.siteMetadata.locale
    const translations = this.props.data.translations
    const tr = (code) => trFunction(code, translations, locale)
    const thisPage = this.props.data.thisPage
    const allNews = this.props.data.allNews.edges
    const allNewsCats = this.props.data.allNewsCats.edges
    const defaultFilter = [{ node: { title: tr('ALL_NEWS'), slug: 'all' } }]
    const filters = defaultFilter.concat(allNewsCats)

    const manualAddAfter = (
      <li>
        <LinkWrap to="/press">{tr('PRESS')}</LinkWrap>
      </li>
    )

    return (
      <Layout>
        <div id="content">
          <Title title={thisPage.title} />
          <header id="categories" className="categories-header">
            <div className="wrap categories-header-inner">
              <ul className="cats-desktop">
                {filters.map((newsCat, i) => (
                  <NewsCat
                    key={i}
                    title={newsCat.node.title}
                    slug={newsCat.node.slug}
                    setFilterValue={this.setFilterValue}
                    activeFilterValue={this.state.activeFilterValue}
                  />
                ))}
                <li>
                  <LinkWrap to="/press">{tr('PRESS')}</LinkWrap>
                </li>
              </ul>

              <Dropdown
                filters={filters}
                setFilterValue={this.setFilterValue}
                activeFilterValue={this.state.activeFilterValue}
                tr={tr}
                manualAddAfter={manualAddAfter}
              />
            </div>
          </header>
          <section className="page-content wrap">
            <ul id="ul-news-list" className="news-list">
              {allNews.map((news, i) => (
                <News
                  key={i}
                  news={news.node}
                  locale={locale}
                  tr={tr}
                  activeFilterValue={this.state.activeFilterValue}
                />
              ))}
            </ul>
          </section>
        </div>
      </Layout>
    )
  }
}
export default NewsPage

export const newsPageQuery = graphql`
  query {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    allNews: allContentfulNews(sort: { fields: [datePosted], order: DESC }) {
      edges {
        node {
          title
          slug
          image {
            ...GatsbyImage
          }
          datePosted
          excerpt {
            childMarkdownRemark {
              html
            }
          }
          category {
            slug
          }
        }
      }
    }
    allNewsCats: allContentfulNewsCategories(
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          title
          slug
        }
      }
    }
    thisPage: contentfulPages(slug: { eq: "news" }) {
      title
      slug
    }
  }
`
